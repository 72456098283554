import React from 'react'
import styled from 'styled-components'

// Components
import Layout from 'components/layout/Layout'
import BannerDefault from 'components/flex/Banner/BannerDefault'

// Elements
import ParseContent from 'components/shared/ParseContent'
import NavLink from 'components/shared/NavLink'
import BlockDefault from 'components/elements/Blocks/BlockDefault'
import { ButtonSecondaryTransparentCSS } from 'components/elements/Buttons/ButtonSecondaryTransparent'

const PageTitle = styled(ParseContent)`
  color: ${({ theme }) => theme.color.light};
  font-family: ${({ theme }) => theme.font.family.secondary};
  margin-top: 10rem;
`

const StyledNavLink = styled(NavLink)`
  ${ButtonSecondaryTransparentCSS}
  transition: all .1 ease-in-out;
  &:hover {
    background-color: ${({ theme }) => theme.color.primary};
    color: ${({ theme }) => theme.color.light};
  }
`

const Inner = styled.div`
  & a:hover {
    text-decoration: underline !important;
  }
`

const NotFoundPage = () => (
  <Layout>
    <BannerDefault>
      <PageTitle className="text-white" content="<h1>404</h1>" />
    </BannerDefault>
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <BlockDefault noHoverEffect noBorder>
            <Inner className="p-sm-4">
              <h2>Helaas, deze pagina kon niet worden gevonden</h2>
              <p>
                De link waar je op klikte leidt naar een niet bestaande pagina.
                Heb je een vraag of opmerking? Neem dan telefonisch contact met
                ons op via
                <a href="tel:0885422301"> 088 - 542 2301 </a>
                of per mail via
                <a href="mailto:info@borg-osc.nl"> info@borg-osc.nl</a>.
              </p>
            </Inner>

            <StyledNavLink to="/" className="p-3">
              Terug naar onze website
            </StyledNavLink>
          </BlockDefault>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
